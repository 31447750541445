/* General Styles */
body {
  margin: 0;
  font-family: "Office Code Pro", Courier, monospace;
  background-color: #000;
  color: #fff;
}

.home-page {
  background-color: #000;
}

/* Hero Section */
.hero {
  text-align: center;
  background-color: #000;
  padding: 2rem 0;
  width: 100%;

  h1 {
    font-size: 13vw;
    letter-spacing: 0.05em;
    font-weight: bold;
    font-family: "Press Start 2P", cursive;
    margin: 0;
    text-transform: uppercase;
    line-height: 0.9;
    color: #ffffff;
    border-right: 0.15em solid #fff;
    white-space: nowrap;
    overflow: hidden;
    animation:
      typing 1.2s steps(7, end) forwards,
      blink-caret 0.75s step-end 5;
  }

  p {
    font-size: 1.2rem;
    color: #888;
    margin-top: 2rem;
    font-family: "Montserrat", sans-serif;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: #fff;
  }
}

/* Featured Articles Section */
.featured-articles {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;

  h2 {
    font-family: "Montserrat", sans-serif;
    color: #fff;
    margin-bottom: 2rem;
  }
}

.article-preview-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem; /* Add some spacing between the articles */
}

.article-preview {
  background-color: #000;
  padding: 2rem;
  border-radius: 0;
  position: relative;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #111;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #333;
  }

  h3 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    font-weight: bold;
    font-family: "Montserrat", sans-serif;
    transition: color 0.3s ease;
    color: #fff;
  }

  &:hover h3 {
    color: #f6f6f6;
  }

  p {
    font-size: 1.1rem;
    color: #888;
    line-height: 1.4;
  }

  .article-meta {
    margin-top: 1rem;
    color: #666;
    font-size: 0.9rem;

    span {
      margin-right: 1rem;
    }
  }
}
.article-preview img {
  width: 100%; /* Ensures the image takes up the full width of its container */
  height: auto; /* Maintains the aspect ratio */
  object-fit: contain; /* Ensures the full image is visible without cropping */
  display: block; /* Removes any inline spacing issues */
  margin: 0 auto; /* Centers the image within its container */
}

@media (min-width: 768px) {
  .article-preview:not(:last-child) {
    border-bottom: none;
  }

  .article-preview:not(:last-child)::after {
    content: "";
    position: absolute;
    right: 0;
    top: 10%;
    height: 80%;
    width: 1px;
    background-color: #333;
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero h1 {
    font-size: 2.5rem;
  }
}
@media (max-width: 768px) {
  .article-preview img {
    width: 100%;
    height: auto;
  }

  .article-preview-grid {
    gap: 0.5rem; /* Adjust gap for smaller screens */
  }
}
