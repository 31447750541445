.submit-article-page {
  background-color: #000;
  min-height: 100vh;
  padding: 2rem;

  h1 {
    font-family: "Press Start 2P", cursive;
    color: #fff;
    font-size: 2rem;
    text-align: center;
    margin-bottom: 2rem;
    text-transform: uppercase;
  }

  .article-form {
    max-width: 800px;
    margin: 0 auto;
    background-color: #111;
    padding: 2rem;
    border-radius: 8px;

    .form-group {
      margin-bottom: 1.5rem;

      label {
        display: block;
        font-family: "Montserrat", sans-serif;
        color: #fff;
        margin-bottom: 0.5rem;
      }

      input,
      textarea,
      select {
        width: 100%;
        padding: 0.8rem;
        background-color: #000;
        border: 1px solid #333;
        color: #fff;
        font-family: "Office Code Pro", Courier, monospace;
        border-radius: 4px;

        &:focus {
          outline: none;
          border-color: #666;
        }
      }

      textarea {
        min-height: 200px;
        resize: vertical;
      }
    }

    button {
      width: 100%;
      padding: 1rem;
      background-color: #333;
      color: #fff;
      border: none;
      border-radius: 4px;
      font-family: "Press Start 2P", cursive;
      font-size: 0.9rem;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #444;
      }
    }
  }
}
