.article-detail {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  padding: 2rem;
  box-sizing: border-box;

  .article-image-wrapper {
    width: 100%; /* Ensure wrapper spans the full container width */
    max-width: 100%; /* Prevent overflow */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Prevent content overflow */
  }

  .article-image {
    width: 100%; /* Fit the container width */
    height: auto; /* Maintain aspect ratio */
    max-height: 60vh; /* Restrict height to 60% of viewport */
    object-fit: contain; /* Ensure the full image is visible */
    display: block; /* Prevent inline-block issues */
  }

  .article-content {
    text-align: left; /* Align text to the left */
    p {
      margin-bottom: 1rem; /* Add spacing between paragraphs */
      line-height: 1.6; /* Improve readability */
      color: #fff; /* Ensure text is visible on dark backgrounds */
      font-family: "Office Code Pro", Courier, monospace;
    }
  }

  .article-back {
    margin-top: 2rem;

    a {
      text-decoration: none; /* Remove underline */
      color: #fff; /* Make hyperlinks white */
      font-weight: bold;

      &:hover {
        text-decoration: underline; /* Add underline on hover */
        color: #ccc; /* Slightly lighter color on hover */
      }
    }
  }

  @media (max-width: 768px) {
    .article-image {
      max-height: 40vh; /* Shrink height for smaller screens */
      width: 100%; /* Fit container width */
    }
  }
}

html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}
