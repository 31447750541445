.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  .nav-left {
    display: flex;
    gap: 2rem; // Space between links
    flex-wrap: wrap; // Allow items to wrap on smaller screens

    a {
      text-decoration: none;
      color: #333;
      white-space: nowrap; // Prevent text from breaking
      font-size: clamp(0.8rem, 2.5vw, 1rem); // Responsive font size

      &:hover {
        color: #666;
      }
    }
  }

  .nav-right {
    display: flex;
    gap: 2rem;
    flex-wrap: wrap; // Allow items to wrap
    
    a {
      text-decoration: none;
      color: #333;
      white-space: nowrap;
      font-size: clamp(0.8rem, 2.5vw, 1rem);

      &:hover {
        color: #666;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 0.75rem 1rem; // Slightly reduced padding
    gap: 1rem; // Add gap between nav sections
    
    .nav-left, .nav-right {
      gap: 1rem; // Reduce gap between links on mobile
    }
  }
}