/* Article List Page Styles */
.articles-page {
  background-color: #000;
  min-height: 100vh;
  padding: 2rem;

  h1 {
    font-family: "Press Start 2P", cursive;
    color: #fff;
    font-size: 9vw;
    text-align: center;
    margin: 0;
    padding: 2rem 0;
    text-transform: uppercase;
    position: relative;
    background: #000;

    /* Remove any potential artifacts */
    &::before,
    &::after {
      content: none;
    }
  }

  /* Optional: Add a pixel-style underline */
  h1::after {
    content: "";
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    width: 60%;
    height: 2px;
    background: linear-gradient(
      to right,
      transparent,
      #333 20%,
      #333 80%,
      transparent
    );
  }

  .articles-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 1rem;
  }

  .article-card {
    background-color: #000;
    padding: 1.5rem;
    position: relative;
    transition: background-color 0.3s ease;
    border-bottom: none;
    text-decoration: none;
    color: inherit;
    cursor: pointer;
    border: 1px solid #333;
    margin-bottom: 1.5rem;

    &:hover {
      background-color: #111;
      transform: translateY(-2px);
      transition: transform 0.3s ease;
    }

    &::after {
      display: none;
    }

    h2 {
      font-family: "Montserrat", sans-serif;
      font-size: 1.5rem;
      margin-bottom: 0.8rem;
      color: #fff;
      transition: color 0.3s ease;
    }

    &:hover h2 {
      color: #f6f6f6;
    }

    p {
      font-family: "Office Code Pro", Courier, monospace;
      color: #888;
      line-height: 1.4;
      margin-bottom: 0.8rem;
      max-height: 2.8em;
      overflow: hidden;
    }

    .article-meta {
      font-family: "Montserrat", sans-serif;
      color: #666;
      font-size: 0.8rem;
      margin-top: 0.8rem;

      span {
        margin-right: 1rem;
      }
    }

    .category-tag {
      display: inline-block;
      padding: 0.3rem 0.8rem;
      background-color: #222;
      color: #fff;
      border-radius: 3px;
      font-size: 0.8rem;
      margin-bottom: 1rem;
    }

    img {
      width: 100%;
      height: 220px;
      object-fit: cover;
      margin-bottom: 0.8rem;
      transition: transform 0.3s ease;

      &:hover {
        transform: scale(1.02);
      }
    }

    &:nth-child(3) img {
      height: 250px;
    }
  }
  .no-articles {
    text-align: center; /* Center the text */
    color: #fff; /* Set text color to white */
    font-size: 1.5rem; /* Adjust font size */
    margin-top: 2rem; /* Add some margin above */
  }
  .articles-back {
    text-align: center; /* Center the "Back to Home" link */
    margin-top: 2rem;

    a {
      font-family: "Montserrat", sans-serif;
      color: #fff; /* White color */
      text-decoration: none;
      font-size: 1.2rem;
      font-weight: bold;
      padding: 0.5rem 1rem;
      border: 1px solid #333;
      border-radius: 5px;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #111;
        color: #ccc; /* Slightly lighter color on hover */
      }
    }
  }

  @media (max-width: 1200px) {
    .articles-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width: 768px) {
    .articles-grid {
      grid-template-columns: 1fr;
    }
  }
}
